import { Amplify } from "aws-amplify";

/** USER revision key */
export const revisionKey = "ReleaseRevision";
export const revisionTarget = "user";

export const errorMessage: { [key: string]: string } = {
  inputBlankError: "入力必須項目です",
  selectBlankError: "選択必須項目です",
  wordCountError: "500文字以内でご記入ください",
  dateBlankError: "'時間'のみの入力は不可です",
  timeBlankError: "'年月日'のみの入力は不可です",
  futureDateError: "未来日付の入力は不可です",
  isVirusError: "セキュリティチェックエラーの為、該当画像を削除してください",
};

/** Lambda Resolver のswitchキー */
export const apiName = {
  addTicket: "addTicket",
  addQuestionTicket: "addQuestionTicket",
};

/** Lambda Resolver のswitchキー */
export const screenName = {
  top: "トップ",
  input: "新規入力",
  detail: "詳細",
  check: "内容確認",
  complete: "完了",
};

// 戻り先URL取得時のデフォルトPartitionKey
export const DefaultReturnUrlKey = "default";

// 注意文言取得時のデフォルトPartiionKey
export const DefaultAttentionMsgKey = "default";

// ステータスコード
export const statusCode = {
  timeout: "408",
};

// カラーコード
export const color = {
  red: "#d32f2f",
};

// 操作アクション
export const targetPage = {
  newInquiry: "新規問い合わせ",
  confirmNewInquiryDetails: "新規問い合わせ詳細確認",
};
export const pageAction = {
  display: "表示",
  showFaqAnswer: "回答を見る",
  contactUs: "問い合わせをする",
};

// ################## 解約申請 #########################
// 解約申請サービスID
export const CANCEL_SERVICE_ID = "SV1001";

// API Name
export const API_NAME_CANCEL = "cancel";

/** Lambda Resolver のswitchキー */
export const cancelScreenName = {
  detail: "追加質問詳細",
  check: "追加質問内容確認",
  complete: "追加質問完了",
};

export const connectionV1API = () => {
  Amplify.configure({
    aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
    aws_appsync_graphqlEndpoint:
      process.env.REACT_APP_AWS_APPSYNC_GRAPHQLENDPOINT,
    aws_appsync_region: process.env.REACT_APP_AWS_APPSYNC_REGION,
    aws_appsync_authenticationType:
      process.env.REACT_APP_AWS_APPSYNC_AUTHENTICATIONTYPE,
    aws_cognito_identity_pool_id:
      process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
    aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
    aws_user_pools_web_client_id:
      process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
    oauth: {},
  });
};

export const connectionV2API = () => {
  Amplify.configure({
    aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
    aws_appsync_graphqlEndpoint:
      process.env.REACT_APP_AWS_APPSYNC_GRAPHQLENDPOINTV2,
    aws_appsync_region: process.env.REACT_APP_AWS_APPSYNC_REGION,
    aws_appsync_authenticationType:
      process.env.REACT_APP_AWS_APPSYNC_AUTHENTICATIONTYPE,
    aws_cognito_identity_pool_id:
      process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
    aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
    aws_user_pools_web_client_id:
      process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
    oauth: {},
  });
};

// サブカテゴリ 未選択
export const SubCategoryUnselected = 999;

/** CiRCUS API */
export const CiRCUSuw8001 = {
  type: "uw8001",
  requestUser: "user",
};

/** 解約申請閉塞対応 */
export const NotFound = "404";

import { createTheme } from "@mui/material/styles";

export const ColorTheme = createTheme({
  palette: {
    primary: {
      main: process.env.REACT_APP_COLOR_PRIMARY_MAIN as string | "",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
});

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch } from "app/store";
import {
  selectIsRevisionMatch,
  authInfo,
  setUuid,
  setAuthInfo,
  setLoading,
  selectDisplayServiceId,
  fetchAsyncGetTicketInfoServiceId,
  setUserAuthInfo,
  getServiceCloseInfo,
  setIsRevisionMatch,
} from "reducks/slice";
import { authCallItems } from "reducks/types";
import { checkLoggedIn, circusAuth, setSession } from "graphql/call/auth";
import { authConst } from "common/const";
import { statusCode, CANCEL_SERVICE_ID } from "common/common";
import ViewLoad from "containers/ViewLoad";
import ConfirmationModal from "containers/ConfirmationModal";
import { useSubscribeRevision } from "hooks/revision";
import { v6 as uuidv6 } from "uuid";
import { useEffectEvent } from "hooks/effectEvent";

/**
 * dアカウント認証確認
 * @param props
 * @returns
 */
const Auth: React.FC = (props) => {
  // uuid をブラウザセッションidとするのに使用
  const uuid = uuidv6();
  /** 解約申請閉塞対応 */
  const pathname = window.location.pathname;
  const url = new URL(window.location.href);
  let params = url.searchParams;
  // 解約申請閉塞 or サービス終了フラグ
  let errorFlag = false;
  //URLからサービスID/チケットNoを取得
  let serviceId = params.get("serviceId");
  const ticketNumber = params.get("ticketNumber");
  const dispatch: AppDispatch = useDispatch();
  if (
    (pathname === "/cancel" || pathname === "/cancel/" || pathname === "/") &&
    params.get("authredirect") !== "1"
  ) {
    if (params.has("serviceId")) {
      const getServiceInfo = async (serviceId: string) => {
        const serviceInfo = await getServiceCloseInfo(serviceId);
        /**
         * サービス情報が存在しない
         * サービス終了済
         * 151サービス
         * リリース前サービス
         * 上記の場合エラー画面へ遷移
         */
        if (
          !serviceInfo.getCIMPF ||
          serviceInfo?.getCIMPF?.serviceCloseFlag ||
          serviceInfo?.getCIMPF?.telServiceFlg ||
          serviceInfo?.getCIMPF?.releaseFlg === false
        ) {
          window.location.href = "/error?code=404";
          errorFlag = true;
        } else {
          ticketNumber &&
            dispatch(fetchAsyncGetTicketInfoServiceId(ticketNumber));
        }
      };
      getServiceInfo(params.get("serviceId") as string);
    } else {
      window.location.href = "/error?code=404";
      errorFlag = true;
    }
  }
  // リリース通知用フラグ
  const isRevisionMatch = useSelector(selectIsRevisionMatch);
  // ログイン情報を取得
  const auth = useSelector(authInfo);
  // サービスIDを取得
  const displayServiceId = useSelector(selectDisplayServiceId);

  // 認証確認までコンポーネントを表示させない
  const [data, setData] = useState(false);

  displayServiceId
    ? localStorage.setItem("displayServiceId", displayServiceId)
    : localStorage.setItem("displayServiceId", serviceId ? serviceId : "");

  const handleConfirmClick = () => {
    window.location.reload();
  };
  const handleCloseClick = () => {
    dispatch(setIsRevisionMatch(true));
  };

  const onVisit = useEffectEvent(async () => {
    const searchParams = new URL(window.location.href).searchParams;
    // CiRCUS認証の戻り
    if (
      searchParams.get(authConst.redirectParam.key) &&
      searchParams.get(authConst.redirectParam.key) ===
        authConst.redirectParam.value
    ) {
      requestAndCreateSession(searchParams);
      return null;
    } else {
      // 通常
      /** セッション確認 */
      const checkSession = async () => {
        await checkLoggedIn()
          .then(async (session: any) => {
            // uuid 設定
            dispatch(setUuid(uuid));
            /** session.maskdocomoId がある(DBにある)場合は旧リクエスト */
            if (session.maskdocomoId) {
              /** ログイン情報を設定 */
              if (auth.accountIdentifier === "" || auth.maskdocomoId === "") {
                dispatch(setAuthInfo(session));
              }
            } else {
              /** ログイン情報を設定 */
              // アカ識
              if (auth.accountIdentifier === "") {
                dispatch(setAuthInfo(session));
              }
              // ドコモID
              if (!auth.maskdocomoId) {
                dispatch(setUserAuthInfo(session.accountIdentifier));
              }
            }
            // コンポーネントレンダリング
            setData(true);
            // ローディング非表示
            dispatch(setLoading(false));
          })
          .catch((e) => {
            window.location.href = "/error?code=086";
            throw new Error();
          });
      };
      if (!auth.accountIdentifier) {
        checkSession();
      }
    }
  });
  useEffect(() => {
    !errorFlag && onVisit();
  }, [errorFlag, onVisit]);

  // リリース通知 subscribe
  useSubscribeRevision(data);

  return data ? (
    <div>
      <ConfirmationModal
        open={!isRevisionMatch}
        onConfirm={handleConfirmClick}
        onClose={handleCloseClick}
      />
      {props.children}
    </div>
  ) : (
    <ViewLoad />
  );
};

/** CiRCUS 認証後 */
const requestAndCreateSession = async (searchParams: URLSearchParams) => {
  const state = getParams(searchParams, authConst.state);
  const error = searchParams.get("error");

  /* 認証パスを設定 */
  const path = state.includes("/cancel") ? "cancel" : "/";
  /* cancel リスクベース対応 */
  // cancel の場合を lambda で判断するために path を : でつなげる
  const code = getParams(searchParams, authConst.code) + `:${path}`;
  // エラー画面の戻り対応
  if (state.includes("/cancel")) {
    localStorage.setItem("displayServiceId", CANCEL_SERVICE_ID);
  }

  try {
    // circus auth
    if (error || !code) {
      const e = error === null ? "circus auth error" : error;
      throw new Error(e);
    }
    // リクエストが認証要求からのリダイレクトか確認
    if (!checkFromAuthRedirect(state)) {
      // エラー
      throw new Error("redirect error not match state");
    }

    /* token 要求、利用者情報要求 */
    const session: authCallItems = (await circusAuth({
      code,
    })) as authCallItems;
    if (!session.sessionId || !session.hashId) {
      // エラー
      throw new Error("not found responce");
    }
    /* セッションIDを cookie に保存 */
    setSession(session);
    sessionStorage.setItem("exPath", path);
    /* 認証前のパスに遷移 */
    doRedirect();
  } catch (e: any) {
    if (
      Array.isArray(e.errors) &&
      e.errors.length > 0 &&
      e.errors[0]?.errorType === statusCode.timeout
    ) {
      window.location.href = "/error/timeout";
    } else {
      window.location.href = "/error?code=052";
    }
  }
};

/** URLパラメータの値を返す */
const getParams = (searchParams: URLSearchParams, key: string) => {
  let value = searchParams.get(key);
  if (!value) return "";
  else return value;
};

/** 認証前に発行した state と一致するか確認する */
const checkFromAuthRedirect = (key: string) => {
  const beforeKey = localStorage.getItem(authConst.state);
  localStorage.removeItem(authConst.state);
  return key === beforeKey;
};

/** 認証前の画面に遷移する */
const doRedirect = () => {
  const transition = localStorage.getItem(authConst.transition);
  localStorage.removeItem(authConst.transition);
  window.location.href = transition ? transition : "/";
};

export default Auth;

import { SubCategoryUnselected } from "common/common";
import { ALL_STATE } from "./types";

export const initialState: ALL_STATE = {
  loginType: "",
  loading: true,
  displayNumber: 0,
  displayNumberAdd: 0,
  displayDButton: true,
  daccount: "",
  inquiries: [
    {
      title: "",
      receptionDate: "",
      receptionContent: "",
    },
    {
      title: "",
      receptionDate: "",
      receptionContent: "",
    },
    {
      title: "",
      receptionDate: "",
      receptionContent: "",
    },
  ],
  editedContent: {
    incidentDate: "",
    incidentTime: "",
    serviceId: "",
    accountIdentifier: "",
    inquiryContent: "",
    subCategory: SubCategoryUnselected,
  },
  addInquiryContent: {
    addInquiry: "",
  },
  serviceNameList: [""],
  inquiryAnswerList: [
    {
      inquiry: "",
      answer: "",
      inquiryTime: "",
      answerTime: "",
    },
  ],
  questionDetailList: [
    {
      ticketNumber: "",
      place: "",
      Time: "",
      Contents: "",
    },
  ],
  services: [],
  serviceIdName: [],
  ticketNumber: "",
  occurrenceDate: 0,
  displayTicketInfo: [],
  displayTicketInfoId: [],
  ticketInfomation: {
    ticketNumber: "",
    serviceId: "",
    serviceName: "",
    inquiry: "",
    createdAt: "",
    status: SubCategoryUnselected,
  },
  chats: [],
  authInfo: {
    maskdocomoId: "",
    accountIdentifier: "",
  },
  subCategories: [],
  noticeListInfo: [],
  noticeServiceIdListInfo: [],
  existFromService: false,
  displayServiceId: "",
  returnUrlInfo: {
    returnServiceName: "",
    url: "",
  },
  attentionMessage: "",
  ticketInfoCancel: [],
  attachmentMessage: "",
  imageFileInfo: [],
  imagePreview: {
    url: "",
    open: false,
  },
  failedImage: false,
  loadingTransparent: false,
  isFileAttache: false,
  imageNotes: [],
  invalidFileFlag: false,
  uploadingFileFlag: false,
  waitingImageUp: false,
  serviceCloseFlag: null,
  revision: "",
  isRevisionMatch: true,
  isEnabledFAQ: false,
  faqData: [],
  openFaqIds: [],
  uuid: "",
};

import { API, graphqlOperation } from "aws-amplify";
import { circusCallItems, matchAccountIdentifierItems } from "reducks/types";
import {
  getSession,
  matchTicketInfoUserAccountId,
} from "graphql/customQueries";
import { requestAuthAndCreateSession } from "graphql/customMutations";

import {
  authenticationUrl,
  authenticationParameter,
  authenticationParameterAnotherAccount,
  authConst,
  authenticationType,
} from "common/const";

/**
 * token 要求 → 利用者情報要求
 * @param items circusCallItems
 * @returns
 */
export const circusAuth = async (items: circusCallItems) => {
  return new Promise((resolve, reject) => {
    (
      API.graphql(
        graphqlOperation(requestAuthAndCreateSession, {
          input: {
            code: items.code,
            version: "2",
          },
        })
      ) as Promise<any>
    )
      .then((result) => {
        return resolve(result.data.requestAuthAndCreateSession);
      })
      .catch((err) => {
        /* エラー */
        return reject(err);
      });
  });
};

/**
 * セッション確認
 * @param items
 * @returns
 */
export const checkLoggedIn = async () => {
  return new Promise((resolve, reject) => {
    (
      API.graphql(
        graphqlOperation(getSession),
        getAuthHeaderItems()
      ) as Promise<any>
    )
      .then((result) => {
        if (!result.data.isLoggedIn) {
          window.location.href = "/error?code=097";
        }
        if (
          result.data.isLoggedIn?.accountIdentifier === null ||
          result.data.isLoggedIn?.statusCode === "401"
        ) {
          authRedirect(authenticationType.general);
        } else {
          return resolve(result.data.isLoggedIn);
        }
      })
      .catch((err) => {
        /* セッションなし */
        if (
          Array.isArray(err.errors) &&
          err.errors.length > 0 &&
          err.errors[0].errorType === authConst.notFoundSessionError
        ) {
          // CiRCUS 認証へリダイレクト
          authRedirect(authenticationType.general);
        } else {
          /* エラー */
          return reject(err);
        }
      });
  });
};

/** CiRCUS 認証へ遷移 */
export const authRedirect = (req: string) => {
  /* 認証要求生成 */
  const authenticationRequest = getRedirectURL(req);
  /* リダイレクト */
  window.location.href = authenticationRequest;
  return false;
};

/** CiRCUS 認証前処理 */
const getRedirectURL = (req: string) => {
  /**
   * localstrage に state 保存（戻り後に一致しているか確認する）
   * 戻り後の遷移先パスを localstrage に保存
   * 半角スペースが + なのを %20 にリプレース
   */
  const url = new URL(authenticationUrl);
  // 現在のパス
  const locationPath = window.location.pathname;
  // 認証の種類に寄ってパラメータを変更
  let param;
  if (req === authenticationType.another) {
    param = authenticationParameterAnotherAccount;
  } else {
    param = authenticationParameter;
  }

  for (const [key, value] of Object.entries(param)) {
    if (value) {
      let val = value;
      if (key === "redirect_uri" && locationPath.includes("/cancel")) {
        val = value.replace("?", "/cancel?");
      }
      url.searchParams.append(
        unescape(encodeURIComponent(key)),
        unescape(encodeURIComponent(val))
      );
    }
  }
  /** state 追加する */
  const state = new Date().getTime().toString() + locationPath;
  localStorage.setItem(authConst.state, state);
  url.searchParams.append(authConst.state, state);

  /** 認証後のCIMPF戻り先 */
  const transition = window.location.href;
  localStorage.setItem(authConst.transition, transition);

  /** 半角スペースが + なのを %20 にリプレース */
  return url.href.split("+").join("%20");
};

/** cookie からセッション取得情報を取得して返す */
export const getAuthHeaderItems = () => {
  const version = "2";
  const cookies = document.cookie.split("; ");
  let customHeaderItems: any = {};
  for (let cookie of cookies) {
    let line = cookie.split("=");
    if (line[0] === authConst.sessionId || line[0] === authConst.hashId) {
      if (
        decodeURIComponent(line[1]) &&
        decodeURIComponent(line[1]) !== "null"
      ) {
        customHeaderItems[line[0]] = decodeURIComponent(line[1]);
      }
    }
  }
  // セッション処理バージョン設定
  customHeaderItems[authConst.version] = version;
  return customHeaderItems;
};

/** cookie にセッション取得情報を設定する */
export const setSession = (session: any) => {
  const sessionId = encodeURIComponent(session.sessionId);
  const hashId = encodeURIComponent(session.hashId);

  const expdate = session.expdate
    ? `expires=${new Date(session.expdate * 1000).toUTCString()}; `
    : `Max-Age=2592000; `;
  document.cookie =
    `${authConst.sessionId}=${sessionId}; ` + expdate + authConst.cookieParams;
  document.cookie =
    `${authConst.hashId}=${hashId}; ` + expdate + authConst.cookieParams;
};

/**
 * ticket概要、ログインユーザーアカ識照合
 * @param items matchAccountIdentifierItems
 * @returns
 */
export const fetchAsyncCheckTicketAccountId = async (
  items: matchAccountIdentifierItems
) => {
  return new Promise((resolve, reject) => {
    (
      API.graphql(
        graphqlOperation(matchTicketInfoUserAccountId, {
          partitionKey: "TicketInfomation",
          sortKey: items.ticketNumber,
          accountIdentifier: items.accountIdentifier,
        }),
        getAuthHeaderItems()
      ) as Promise<any>
    )
      .then((result) => {
        // 突合結果がfalseの場合、再度dアカウントログイン画面に画面遷移
        // if (!result.data.matchTicketInfoUserAccountId.isMatched) {
        //   authRedirect(authenticationType.another);
        // }
        return resolve(result.data.matchTicketInfoUserAccountId.isMatched);
      })
      .catch((err) => {
        /* チケット番号が存在しない場合、エラーが返却されるため、エラー画面へ */
        window.location.href = "/error?code=055";
        return reject(err);
      });
  });
};

import React from "react";
import { LoadingModal } from "../common/const";

import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Modal from "@material-ui/core/Modal";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  /* TODO 仮 */
  progress: {
    color: "#33CCFF",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -(LoadingModal.size / 2),
    marginLeft: -(LoadingModal.size / 2),
  },
  modalStyle: {
    backgroundColor: "#FFFFFF",
  },
}));

const ViewLoad: React.FC = () => {
  const classes = useStyles();
  /* プログレス */
  return (
    <div data-testid="container">
      <Modal
        open={true}
        closeAfterTransition={true}
        disableEscapeKeyDown={true}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: { enter: 0, exit: LoadingModal.timeout },
          className: classes.modalStyle,
        }}
      >
        <div>
          <CircularProgress
            size={LoadingModal.size}
            className={classes.progress}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ViewLoad;

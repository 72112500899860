import { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import ViewLoad from "containers/ViewLoad";
import { store } from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import Amplify from "aws-amplify";

import Auth from "components/Auth";
import Error from "./containers/Error";

import Timeout from "./containers/Timeout";

import { ThemeProvider } from "@mui/material/styles";
import { ColorTheme } from "common/ColorTheme";
import { path } from "common/Route";

const Super = lazy(() => import("./containers/Super"));
const Additional = lazy(() => import("./containers/Additional"));
const CancelSuper = lazy(() => import("./containers/cancel/CancelSuper"));
const CancelAdditional = lazy(
  () => import("./containers/cancel/CancelAdditional")
);

Amplify.configure({
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_appsync_graphqlEndpoint:
    process.env.REACT_APP_AWS_APPSYNC_GRAPHQLENDPOINT,
  aws_appsync_region: process.env.REACT_APP_AWS_APPSYNC_REGION,
  aws_appsync_authenticationType:
    process.env.REACT_APP_AWS_APPSYNC_AUTHENTICATIONTYPE,
  aws_cognito_identity_pool_id:
    process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  oauth: {},
});
ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <Route exact path="/error" component={Error} />
        <Route exact path="/error/timeout" component={Timeout} />
        <Auth>
          <Suspense fallback={<ViewLoad />}>
            <ThemeProvider theme={ColorTheme}>
              <Route exact path="/" component={Super} />
              <Route exact path="/addQuestions" component={Additional} />
              {/* 解約申請画面 */}
              <Route exact path={path.cancelTop} component={CancelSuper} />
              <Route
                exact
                path={path.cancelAddQuestions}
                component={CancelAdditional}
              />
            </ThemeProvider>
          </Suspense>
        </Auth>
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
serviceWorker.unregister();

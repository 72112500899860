export const regenerationSession = /* GraphQL */ `
  mutation RegenerationSession($tableName: String) {
    regenerationSession(tableName: $tableName) {
      sessionId
      hashId
      expdate
      statusCode
    }
  }
`;

export const addTicket = /* GraphQL */ `
  mutation AddTicket(
    $cognitoApiName: String
    $tableName: String
    $input: AddTicketInput!
  ) {
    addTicket(
      cognitoApiName: $cognitoApiName
      tableName: $tableName
      input: $input
    ) {
      ticketNumber
    }
  }
`;

export const requestAuthAndCreateSession = /* GraphQL */ `
  mutation RequestAuthAndCreateSession($input: RequestAuthInput) {
    requestAuthAndCreateSession(input: $input) {
      sessionId
      hashId
      accountIdentifier
      expdate
      maskdocomoId
      lineId
      statusCode
    }
  }
`;

// 追加質問登録
export const addQuestionTicket = /* GraphQL */ `
  mutation CustomAddQuestionTicket(
    $cognitoApiName: String
    $tableName: String
    $input: CustomAddQuestionInput!
  ) {
    customAddQuestionTicket(
      cognitoApiName: $cognitoApiName
      tableName: $tableName
      input: $input
    ) {
      partitionKey
      sortKey
      ticketNumber
    }
  }
`;

/** upload All images */
export const uploadAllImages = /* GraphQL */ `
  mutation uploadAllImages(
    $accountId: String!
    $chatId: String!
    $ticketId: String!
  ) {
    uploadAllImages(
      accountId: $accountId
      chatId: $chatId
      ticketId: $ticketId
    ) {
      statusCode
    }
  }
`;

/** add operation log */
export const addLog = /* GraphQL */ `
  mutation createOperationLog($data: OperationLogInput!) {
    createOperationLog(data: $data) {
      message
      statusCode
    }
  }
`;

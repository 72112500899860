import React from "react";
import { Button, Grid, Modal, Box, Typography } from "@mui/material";
import { color } from "common/common";

interface ConfirmationModalProps {
  open: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  open,
  onClose,
  onConfirm,
}) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      disableScrollLock={true}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          borderRadius: "50px",
          width: "600px",
          bgcolor: "background.paper",
          p: 2,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{ textAlign: "center", padding: "10px 10px 10px 10px" }}
            >
              システムが更新されました。
              <br />
              ​お手数ですが、 <br />
              ​「画面更新」をクリックして <br />
              ​最新化してください。
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              sx={{
                textAlign: "center",
                color: color.red,
                padding: "5px 5px 10px 5px",
              }}
            >
              ※「画面更新」をクリックすると​
              <br />
              入力中の内容がリセットされますので
              <br />
              ご注意ください。
            </Typography>
          </Grid>
          <Grid item xs={12} container justifyContent="center">
            <Button
              variant="contained"
              color="inherit"
              onClick={onClose}
              sx={{
                margin: "10px 20px 10px 20px",
                width: "150px",
                // bgcolor: process.env.REACT_APP_COLOR,
                // ":hover": {
                //   bgcolor: process.env.REACT_APP_HOVER,
                // },
              }}
            >
              後で行う
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={onConfirm}
              sx={{
                margin: "10px 20px 10px 20px",
                width: "150px",
                bgcolor: process.env.REACT_APP_COLOR_PRIMARY_MAIN,
                ":hover": {
                  bgcolor: process.env.REACT_APP_HOVER,
                },
              }}
            >
              画面更新
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;

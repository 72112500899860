import styles from "../styles/Error.module.css";
import { Button } from "@mui/material";
import { CANCEL_SERVICE_ID, NotFound } from "common/common";
import { Typography } from "@mui/material";
import Header from "components/Header";
import Footer from "components/Footer";

const Error: React.FC = () => {
  const id = localStorage.getItem("displayServiceId");
  const localStorageServiceId =
    id === CANCEL_SERVICE_ID ? "/cancel" : id ? `/?serviceId=${id}` : "/";

  const searchParams = new URLSearchParams(window.location.search);
  const erorrCode = searchParams.get("code");
  /** 戻るボタンイベント */
  const handleReturnEvent = () => {
    localStorage.removeItem("displayServiceId");
    window.location.href = localStorageServiceId;
  };

  return (
    <div className={styles.root}>
      <Header title={"エラー"} />
      <div className={styles.text}>
        <Typography
          sx={{
            color: "RED",
            fontSize: "2em",
          }}
          variant="h4"
        >
          {erorrCode === NotFound
            ? "お探しのページが見つかりません"
            : "エラーが発生しました。"}
        </Typography>
      </div>
      <div className={styles.textinfo}>
        <Typography
          sx={{
            color: "RED",
            fontSize: "1em",
            margin: 2,
            marginBottom: "20px",
          }}
          variant="body1"
        >
          {erorrCode === NotFound
            ? "お探しのページはURLが変更または削除された可能性がございます。"
            : "誠に申し訳ありませんが、ブラウザを閉じ初回起動からやり直してください。"}
        </Typography>
        <Typography
          sx={{
            color: "RED",
            fontSize: "1em",
            margin: 2,
          }}
          variant="body1"
        >
          {erorrCode === NotFound
            ? "※ブックマークを登録されている場合は、お手数ですが設定の変更をお願いいたします。"
            : "お客様へ新たに回答が届いた可能性がございます。誠に申し訳ありませんが、ブラウザ再起動後ご確認お願い致します。"}
        </Typography>
      </div>
      {erorrCode === NotFound ? (
        <div></div>
      ) : (
        <div className={styles.button}>
          <Button
            data-testid="btn-to-top"
            onClick={handleReturnEvent}
            variant="contained"
            color="inherit"
            sx={{
              margin: "0px 10px 10px 10px",
              width: "260px",
              height: "45px",
            }}
          >
            {id === CANCEL_SERVICE_ID
              ? "ご解約申請(トップ)画面へ"
              : "お問い合わせ(トップ)画面へ"}
          </Button>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Error;

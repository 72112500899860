import React from "react";
import styles from "../styles/Header.module.css";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";

type props = {
  title?: string;
};

const Header: React.FC<props> = ({ title = "" }) => {
  const text = title !== "" ? `(${title})` : "";
  return (
    <div className={styles.root}>
      <AppBar
        position="fixed"
        sx={{ bgcolor: process.env.REACT_APP_COLOR_PRIMARY_MAIN }}
      >
        <Toolbar>
          <div className={styles.title}>
            <Box
              sx={{ color: "#FAFAFA", pl: 0.5 }}
            >{`お問い合わせ${text}`}</Box>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;

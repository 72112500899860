import React from "react";

import Typography from "@mui/material/Typography";
import styles from "styles/Footer.module.css";
// import AppBar from "@mui/material/AppBar";
// import Toolbar from '@mui/material/Toolbar';,py:1px

const Footer: React.FC = () => {
  return (
    <div className={styles.footer}>
      <Typography
        variant="body2"
        align="center"
        sx={{
          textTransform: "none",
          bgcolor: process.env.REACT_APP_COLOR_PRIMARY_MAIN,
          color: "#FAFAFA",
          py: "2px",
        }}
      >
        {"© NTT DOCOMO"}
      </Typography>
    </div>
  );
};
export default Footer;

/** 認証要求パラメータ */
// url
export const authenticationUrl =
  process.env.REACT_APP_AUTHENTICATION_URL || "/error";
// parameter(通常時)
export const authenticationParameter = {
  scope: "openid accountid_n LineID_ID_n LineID_LINE_n MaskdocomoID",
  response_type: "code",
  client_id: process.env.REACT_APP_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_REDIRECT_URI,
  nonce: "login",
  authif: "1",
  idauth: "1",
};
// parameter(別のdアカウントでログイン時)
export const authenticationParameterAnotherAccount = {
  scope: "openid accountid_n LineID_ID_n LineID_LINE_n MaskdocomoID",
  response_type: "code",
  client_id: process.env.REACT_APP_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_REDIRECT_URI,
  nonce: "login",
  authif: "1",
  idauth: "1",
  prompt: "login",
};
// const
export const authConst = {
  notFoundSessionError: "notFoundSessionId",
  code: "code",
  state: "state",
  transition: "transition",
  sessionId: "sessionId",
  hashId: "hashId",
  version: "version",
  cookieParams: "Secure; SameSite=lax; path=/",
  redirectParam: { key: "authredirect", value: "1" },
};
// アカ識突合時const
export const ticketAuthConst = {
  ticketNumber: "ticketNumber",
};

// ローディングモーダル
export const LoadingModal = {
  timeout: 100,
  size: 120,
};

// 認証要求種別
export const authenticationType = {
  general: "general",
  another: "another",
};

// 問合せに最大入力可能な文字数
export const MAX_INQUIRY_CHAR = 500;

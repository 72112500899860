import styles from "../styles/Error.module.css";
import { Button } from "@mui/material";
import { authConst } from "common/const";

import { Typography } from "@mui/material";
import Header from "components/Header";
import Footer from "components/Footer";

const Timeout: React.FC = () => {
  /** 戻るボタンイベント */
  const handleReturnEvent = () => {
    const transition = localStorage.getItem(authConst.transition) || "/";
    window.location.href = transition;
  };

  return (
    <div className={styles.root}>
      <Header title={"エラー"} />
      <div className={styles.text}>
        <Typography
          sx={{
            color: "RED",
            fontSize: "2em",
          }}
          variant="h4"
        >
          申し訳ございません
        </Typography>
      </div>
      <div className={styles.textinfo}>
        <Typography
          sx={{
            color: "RED",
            fontSize: "1em",
            margin: 2,
            marginBottom: "20px",
          }}
          variant="body1"
        >
          ログイン中にタイムアウトが発生しました。
        </Typography>
        <Typography
          sx={{
            color: "RED",
            fontSize: "1em",
            margin: 2,
          }}
          variant="body1"
        >
          下のボタンより
          <br />
          再度ログインを行ってください。
        </Typography>
      </div>
      <div className={styles.button}>
        <Button
          data-testid="btn-to-top"
          onClick={handleReturnEvent}
          variant="contained"
          color="inherit"
          sx={{
            margin: "0px 10px 10px 10px",
            width: "260px",
            height: "45px",
          }}
        >
          再ログイン
        </Button>
      </div>
      <Footer />
    </div>
  );
};

export default Timeout;
